import React from "react";
import PropTypes from "prop-types";

const BetweenToday = ({ className, color }) => (
  <svg className={className} viewBox="0 0 136.36 71.72">
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path
          fill={color}
          d="M12.42,44.41h5.06V37.84H0v6.57H5.05v27h7.37V44.41Zm15.16-1.25c1.16,0,1.92.8,1.87,2.77l-.13,8.67.13,8.53c.05,2-.71,2.78-1.87,2.78s-1.93-.81-1.88-2.78l.13-8.53-.13-8.67c-.05-2,.71-2.77,1.88-2.77ZM18.37,62c.18,5.54,3.66,9.7,9.21,9.7s9.07-4.16,9.25-9.7l.22-7.42-.22-7.42c-.18-5.54-3.71-9.7-9.25-9.7s-9,4.16-9.21,9.7l-.22,7.42L18.37,62ZM47.91,43c1.39,0,2.28.58,2.28,2.55l-.09,9,.09,8.85c0,2-.89,2.54-2.28,2.54H46.66V43Zm9.52,2.68c-.13-5.63-2.64-7.86-7.06-7.86H39.29V71.36H50.37c4.42,0,6.93-2.24,7.06-7.87l.22-8.89-.22-8.9Zm9,15,1.38-8h.36l1.34,8Zm-.81,5.85h4.74l.76,4.83H77.9L72.54,37.84H63.46L58.1,71.36h6.79l.76-4.83ZM85.77,46.37h-.36l-2.68-8.53H75.67l6.25,21.94V71.36H89.3V59.78l6.17-21.94h-7l-2.68,8.53Z"
        />
        <path
          fill={color}
          d="M8.63,5.14c1.38,0,2.32.71,2.28,2.55l-.05,1.47.05,1.48c0,1.83-.9,2.54-2.28,2.54H7.37v-8Zm-1.26,23V19H8.63c1.38,0,2.32.72,2.28,2.55l-.05,2.06.05,2c0,1.83-.9,2.55-2.28,2.55ZM11,33.52c4.34,0,7.29-3.13,7.46-7.46l.09-2.46c-.26-6-5.36-7-7.59-7.42v-.36c2.23-.58,7.1-1.43,7.46-6.66l-.09-1.7C18,3.13,15.15,0,10.82,0H0V33.52Zm9.34,0h14V27H27.67V19.71h5.4V12.65h-5.4V6.57h6.57V0h-14V33.52ZM48.22,6.57h5.05V0H35.8V6.57h5.05v27h7.37V6.57ZM61.14,0h-7l4.91,33.52h7.56l2.41-16.76h.4l2.42,16.76h7.55L84.34,0h-7L74.64,16.76h-.36L71.55,0H67L64.22,16.76h-.35L61.14,0ZM85.9,33.52H99.84V27H93.27V19.71h5.41V12.65H93.27V6.57h6.57V0H85.9V33.52Zm15.73,0h14V27H109V19.71h5.4V12.65H109V6.57h6.57V0h-14V33.52Zm15.73,0h6.57V16.72h.36l5.54,16.8h6.53V0h-6.57V16.8h-.36L123.35,0h-6V33.52Z"
        />
      </g>
    </g>
  </svg>
);

BetweenToday.defaultProps = {
  className: ``,
  color: `white`
};

BetweenToday.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string
};

export default BetweenToday;
