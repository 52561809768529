/* eslint-disable react/prop-types */

import React, { useContext, useEffect } from "react";
import { Link } from "gatsby";
import { clearAllBodyScrollLocks, disableBodyScroll } from "body-scroll-lock";
import { AppContext } from "~context/AppContext";
import { DocumentContext } from "~context/DocumentContext";
import AndTomorrow from "~components/svg/AndTomorrow";
import BetweenToday from "~components/svg/BetweenToday";
import Cross from "~components/svg/Cross";

const Header = ({ clipped, nav }) => {
  const { menuActive, setMenuActive } = useContext(AppContext);
  const { device } = useContext(DocumentContext);

  useEffect(() => {
    setMenuActive(false);
  }, [device]);

  useEffect(() => {
    if (menuActive) {
      disableBodyScroll();
    } else {
      clearAllBodyScrollLocks();
    }
  }, [menuActive]);

  return (
    <header
      className={`header ${
        clipped
          ? `header--clipped z-50 pointer-events-none text-slate`
          : `z-40 text-off-white`
      } ${
        nav ? `header--nav z-50` : ``
      } w-full fixed top-0 right-0 left-0 pt-4 xs:pb-4`}
    >
      <nav className="grid">
        <div className="header__menu grid-end-12 flex items-start justify-between">
          <Link to="/">
            <BetweenToday
              className="header__logo h-24 md:h-16 sm:h-12"
              color={`${clipped ? `#1A191A` : `#F8F6F1`}`}
            />
          </Link>

          <Link to="/auction" className="block f2 uppercase">
            Auction
          </Link>
        </div>

        <div className="header__menu--touch grid-end-12 relative z-10 flex items-start justify-between">
          <Link to="/">
            <BetweenToday
              className="header__logo h-10"
              color={`${nav ? `#1A191A` : `#F8F6F1`}`}
            />
            <AndTomorrow
              className="header__logo h-10 -mt-5"
              color={`${nav ? `#1A191A` : `#F8F6F1`}`}
            />
          </Link>

          <button
            type="button"
            className="w-8 relative block"
            onClick={() => setMenuActive(!menuActive)}
          >
            {(menuActive && (
              <Cross className="w-full -mt-1" color="#1A191A" />
            )) || (
              <ul className="w-full relative">
                <li className="w-full h-1 relative block mb-1 bg-off-white" />
                <li className="w-full h-1 relative block mb-1 bg-off-white" />
                <li className="w-full h-1 relative block mb-1 bg-off-white" />
              </ul>
            )}
          </button>
        </div>
      </nav>
    </header>
  );
};

export default Header;
