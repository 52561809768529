import React, { useContext } from "react";
import { Link } from "gatsby";
import { AppContext } from "~context/AppContext";
import Header from "~components/Header";

const Nav = () => {
  const { menuActive, setMenuActive } = useContext(AppContext);

  return (
    <nav
      className={`nav ${
        menuActive ? `` : `opacity-0 pointer-events-none`
      } transition-opacity w-screen h-screen fixed top-0 right-0 bottom-0 left-0 z-50 flex items-center justify-center bg-off-white text-center uppercase text-slate`}
    >
      <Header nav />

      {menuActive && (
        <ul>
          <li className="animation-appear animation-delay-1">
            <Link
              to="/about"
              className="f1"
              onClick={() => setMenuActive(false)}
            >
              About
            </Link>
          </li>

          <li className="animation-appear animation-delay-2">
            <Link to="/" className="f1" onClick={() => setMenuActive(false)}>
              Work
            </Link>
          </li>

          <li className="animation-appear animation-delay-3">
            <Link
              to="/artists"
              className="f1"
              onClick={() => setMenuActive(false)}
            >
              Index
            </Link>
          </li>

          <li className="animation-appear animation-delay-4 mt-4">
            <Link
              to="/auction"
              className="f1"
              onClick={() => setMenuActive(false)}
            >
              Auction
            </Link>
          </li>
        </ul>
      )}
    </nav>
  );
};

export default Nav;
